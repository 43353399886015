import React from 'react';

// Path to the logo file on your project
import aplazoLogo from './aplazo.png';

const Logo = () => (
  <img src={aplazoLogo} alt="Aplazo" style={{ width: 180 }} />
);

export default Logo;
